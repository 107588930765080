import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { TAB_ELEM_TYPE } from './constant';

export const Elem: React.FC<{
  tabList: TAB_ELEM_TYPE[];
  active?: number | string;
  setActive?: Function;
  justifyContent?: 'space-between' | 'flex-start';
}> = ({
  tabList,
  active,
  setActive = () => {},
  justifyContent = 'space-between',
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const isActive = (value: number | string) => active === value;

  const handleWheel = (event: React.WheelEvent) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += event.deltaY || event.deltaX;
    }
  };

  return (
    <Container
      justifyContent={justifyContent}
      ref={containerRef}
      onWheel={handleWheel}
    >
      {tabList.map((item: TAB_ELEM_TYPE) => (
        <TabItem
          key={item.value}
          active={isActive(item.value)}
          onClick={() => setActive(item.value)}
        >
          <TextElem
            tid={item.tid}
            color={isActive(item.value) ? 'backgroundPrimary' : 'textSecondary'}
            size="tab"
            type={isActive(item.value) ? 'medium' : 'regular'}
          />
        </TabItem>
      ))}
    </Container>
  );
};

const Container = styled.div<{
  justifyContent: 'space-between' | 'flex-start';
}>`
  display: flex;
  align-items: center;
  margin-top: ${Spacing(3)};
  padding: ${Spacing(1)};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  gap: ${Spacing(1)};
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};

  /* Скрываем скроллбар */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
`;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BLOCK]}px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;
  transition: all 0.2s;

  ${({ active }) =>
    active
      ? css`
          background: ${({ theme }) => theme[COLOR_ENUM.BUTTON_PRIMARY]};
        `
      : css`
          &:hover {
            & > span {
              color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
            }
          }
        `};
`;
