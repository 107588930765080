import React from 'react';

import { useLocation } from 'react-router';

import { LayoutElem } from '../../common/layout';
import { ToolbarMenuContainer } from '../toolbar-menu';
import { LayoutPage } from '../../common/layout-page';
import { HeaderContainer } from '../header';
import { WITHOUT_TOOLBAR_PAGE } from '../toolbar-menu/constant';

export const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const currentPath = location.pathname;

  const isToolbarMenu = (path: string) => {
    return !WITHOUT_TOOLBAR_PAGE.some((p) => currentPath.startsWith(p));
  };

  return (
    <>
      <LayoutPage>
        <LayoutElem direction="column">
          <HeaderContainer />
          {children}
        </LayoutElem>
        {isToolbarMenu(currentPath) && <ToolbarMenuContainer />}
      </LayoutPage>
    </>
  );
};
