import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'AUTH_MODULE_NAME';

export const ACTION_TYPE = {
  SET_DATA: `${MODULE_NAME}.SET_DATA`,
  SET_META_DATA: `${MODULE_NAME}.SET_META_DATA`,
  CLEAR_DATA: `${MODULE_NAME}.CLEAR_DATA`,
  SET_SAFETY_DATA: `${MODULE_NAME}.SET_SAFETY_DATA`,
};

export interface USER_DATA_INTER {
  id: number;
  role: USER_ROLE;
  name: string;
  email: string;
}

export interface META_DATA_INTER {
  haveLogin?: boolean;
  haveWellcome?: boolean;
}

export interface ACTION_DATA_INTER {
  user: USER_DATA_INTER;
}

export interface STORE_INTER {
  logged: boolean | null;
  user: USER_DATA_INTER | null;
}

export interface LOCAL_STORE_INTER {
  token: string | null;
  user: USER_DATA_INTER | null;
}

export const API = {
  GET_TOKEN: {
    TYPE: 'POST',
    URL: '/auth/token',
  },
};

export interface GET_TOKEN_ACTION_RESPONSE_INTER {
  accessToken: string;
}

export enum USER_ROLE {
  USER = 1,
  ADMIN = 2,
  CURATOR = 3,
  ANALYST = 4,
}

export const USER_ROLE_VIEW = {
  [USER_ROLE.ADMIN]: 'USER.ROLE.ADMIN',
  [USER_ROLE.USER]: 'USER.ROLE.USER',
  [USER_ROLE.CURATOR]: 'USER.ROLE.CURATOR',
  [USER_ROLE.ANALYST]: 'USER.ROLE.ANALYST',
};

export enum AUTH_TYPE {
  EMAIL = 'email',
}
