import {
  EARNING_ITEM_DATA_RAW_INTER,
  EARNING_STATUS_VIEW,
} from '../earning-admin-list/constant';
import { convertDateToListTimezone } from '../../lib/lang/service';
import { DATA_INTER } from './constant';

export const convert = (data: EARNING_ITEM_DATA_RAW_INTER[]): DATA_INTER => {
  const list = data?.map((e: EARNING_ITEM_DATA_RAW_INTER) => {
    const convert = {
      ...e,
      status: EARNING_STATUS_VIEW[e.status],
      statusDefault: e.status,
      createDate: convertDateToListTimezone(e.createDate),
    };

    return convert;
  });

  return { list, isEmpty: list?.length < 1 };
};
