import uaSvg from '../../asset/svg/lang/ua.svg';
import plSvg from '../../asset/svg/lang/pl.svg';
import ruSvg from '../../asset/svg/lang/ru.svg';
import { LANGUAGE_TAG_ENUM } from '../../lib/lang/value';

export const DATA = [
  {
    lang: 'UA',
    icon: uaSvg,
    value: LANGUAGE_TAG_ENUM.UK,
  },
  {
    lang: 'PL',
    icon: plSvg,
    value: LANGUAGE_TAG_ENUM.PL,
  },
  {
    lang: 'RU',
    icon: ruSvg,
    value: LANGUAGE_TAG_ENUM.RU,
  },
];
