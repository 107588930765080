import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { convert } from './convert';
import { USER_BALANCE_ANALYTIC_MODULE_NAME } from '../user-balance-analytic';
import { usePaginatedData } from '../../lib/util/usePagintationData';
import { useHistory } from 'react-router';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER } from '../../data/auth/constant';
import { PROFILE_UPDATE_PAGE_PATH } from '../../page/profile-update';

export const Container: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const history = useHistory();
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const pageSize = 10;

  const onSuccess = (data: any) => {
    const totalPage = data?.pages[0].total;

    const isEmail = !!state.user?.email;

    const isHaveEarning = data?.pages[0].list?.length > 0;

    if (!isEmail && isHaveEarning) {
      history.push(PROFILE_UPDATE_PAGE_PATH);
    }

    if (totalPage) {
      setPageCount(totalPage);
    }
  };

  const {
    data: preFetch,
    isLoading,
    isError,
    isSuccess,
    error,
  } = usePaginatedData(
    action,
    MODULE_NAME,
    {
      pageParam: page,
      pageSize,
    },
    onSuccess,
  );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const earningList = useMemo(() => {
    const list = preFetch?.pages[0].list as unknown as any;

    if (list) {
      const convertedList = convert(list);

      return { ...convertedList };
    }
  }, [preFetch]);

  const preFetchBalance = useQuery(USER_BALANCE_ANALYTIC_MODULE_NAME);

  const isLoadingBalanceData = () => {
    if (preFetchBalance.isLoading || preFetchBalance.isFetching) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const errorResponse: ACTION_ERROR_INTER = error as ACTION_ERROR_INTER;
    return errorResponse ? errorResponse.message : undefined;
  };

  return (
    <Component
      pageSize={pageSize}
      page={page}
      onChangePage={handlePageChange}
      pageCount={pageCount}
      data={earningList}
      isLoadingBalanceData={isLoadingBalanceData()}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      errorMessage={getErrorMessage()}
    />
  );
};
