import { USER_EARNING_STATUS } from '../earning-admin-list/constant';

export const MODULE_NAME = 'USER_EARNING_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `earning/user-earning-list/${params}`,
};

export interface USER_EARNING_ITEM_DATA_RAW_INTER {
  id: string;
  amount: string;
  status: USER_EARNING_STATUS;
  comment: string;
  createDate: string;
  event: {
    title: string;
    id: string;
  };
}

export interface USER_EARNING_ITEM_LIST_DATA_RAW_INTER {
  list: USER_EARNING_ITEM_DATA_RAW_INTER[];
}

export interface USER_EARNING_ITEM_DATA_INTER
  extends Omit<USER_EARNING_ITEM_DATA_RAW_INTER, 'status'> {
  statusDefault: USER_EARNING_STATUS;
  status: string;
}

export interface EARNING_ITEM_LIST_DATA_INTER {
  list?: USER_EARNING_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface DATA_INTER extends EARNING_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER
  extends USER_EARNING_ITEM_LIST_DATA_RAW_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
