export const MODULE_NAME = 'PROFILE_UPDATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum SKILL_ENUM {
  MONTAGE_PHONE = 1,
  MONTAGE_PROFESSIONAL = 2,
  PHOTO_PROCESSING = 3,
  WRITING_TEXTS = 4,
  WEB_DEVELOPMENT = 5,
  AUDIENCE_GROWTH = 6,
  AUDIENCE_DISTILL = 7,
  PUBLIC_SPEAKING = 8,
}

export const SKILL_LIST_SHORT = [
  {
    value: SKILL_ENUM.MONTAGE_PHONE,
    label: 'Монтировать на телефоне',
  },
  {
    value: SKILL_ENUM.MONTAGE_PROFESSIONAL,
    label: 'Монтировать видео',
  },
  { value: SKILL_ENUM.PHOTO_PROCESSING, label: 'Обрабатывать фотографии' },
  {
    value: SKILL_ENUM.WRITING_TEXTS,
    label: 'Хорошо пишу тексты',
  },
  { value: SKILL_ENUM.WEB_DEVELOPMENT, label: 'Делать сайты' },
  {
    value: SKILL_ENUM.AUDIENCE_GROWTH,
    label: 'Набирать подписчиков',
  },
  {
    value: SKILL_ENUM.AUDIENCE_DISTILL,
    label: 'Перегонять аудиторию из TikTok/Insta',
  },
  { value: SKILL_ENUM.PUBLIC_SPEAKING, label: 'Имею опыт выступлений' },
];

export const SKILL_LIST = [
  {
    value: SKILL_ENUM.MONTAGE_PHONE,
    label: 'Монтировать видео на телефоне (Reels/TikTok)',
  },
  {
    value: SKILL_ENUM.MONTAGE_PROFESSIONAL,
    label: 'Монтировать видео в профессиональных видео-редакторах',
  },
  { value: SKILL_ENUM.PHOTO_PROCESSING, label: 'Обрабатывать фотографии' },
  {
    value: SKILL_ENUM.WRITING_TEXTS,
    label: 'Хорошо пишу захватывающие тексты',
  },
  { value: SKILL_ENUM.WEB_DEVELOPMENT, label: 'Делать сайты' },
  {
    value: SKILL_ENUM.AUDIENCE_GROWTH,
    label: 'Набирать подписчиков',
  },
  {
    value: SKILL_ENUM.AUDIENCE_DISTILL,
    label: 'Перегонять аудиторию из TikTok/Insta',
  },
  { value: SKILL_ENUM.PUBLIC_SPEAKING, label: 'Имею опыт выступлений' },
];

export const SKILL_VIEW = {
  [SKILL_ENUM.MONTAGE_PHONE]: 'Монтировать видео на телефоне (Reels/TikTok)',
  [SKILL_ENUM.MONTAGE_PROFESSIONAL]:
    'Монтировать видео в профессиональных видео-редакторах ',
  [SKILL_ENUM.PHOTO_PROCESSING]: 'Обрабатывать фотографии',
  [SKILL_ENUM.WRITING_TEXTS]: 'Хорошо пишу захватывающие тексты',
  [SKILL_ENUM.WEB_DEVELOPMENT]: 'Делать сайты',
  [SKILL_ENUM.AUDIENCE_GROWTH]: 'Набирать подписчиков',
  [SKILL_ENUM.AUDIENCE_DISTILL]: 'Перегонять аудиторию из TikTok/Insta',
  [SKILL_ENUM.PUBLIC_SPEAKING]: 'Имею опыт выступлений',
};

export enum FORM_VALUE_ENUM {
  EMAIL = 'email',
  FULL_NAME = 'fullName',
  PHONE = 'phone',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.EMAIL]: string;
  [FORM_VALUE_ENUM.FULL_NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
}

export const API = {
  PATCH: {
    TYPE: 'PATCH',
    URL: 'user/profile-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/profile-data',
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface MUTATION_PROPS {
  values: FORM_VALUE_INTER;
  query: string;
}
