import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FormikValues, useFormik } from 'formik';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
  SKILL_VIEW,
} from './constant';

import { Component } from './component';
import {
  email,
  phone,
  required,
  requiredArray,
} from '../../lib/validation/service';
import { validation } from '../../lib/validation';
import { actionPreFetch, action as fetch } from './action';
import { convert, convertArray } from './convert';
import { appPreloadAllLocalStateData } from '../../data/app/action';
import { useDispatch } from '../../lib/store';

export const Container: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();

  const {
    data: preFetchData,
    isLoading: preFetchIsLoading,
    isError: preFetchIsError,
    error: preFetchError,
  } = useQuery(MODULE_NAME, actionPreFetch);
  const data = preFetchData as unknown as FORM_VALUE_INTER;

  const config = {
    [FORM_VALUE_ENUM.FULL_NAME]: [required],
    [FORM_VALUE_ENUM.EMAIL]: [required, email],
    [FORM_VALUE_ENUM.PHONE]: [required, phone],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const formik: FormikValues = useFormik({
    initialValues: {
      [FORM_VALUE_ENUM.FULL_NAME]: data?.fullName,
      [FORM_VALUE_ENUM.EMAIL]: data?.email,
      [FORM_VALUE_ENUM.PHONE]: data?.phone || '',
    },
    enableReinitialize: true,
    validate,
    onSubmit: () => {},
  });

  const onSuccess = () => {
    dispatch(appPreloadAllLocalStateData());
  };

  const action = useMutation(() => fetch(convert(formik.values)), {
    onSuccess,
  });

  const onSubmit = () => {
    return action.mutate();
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
    if (preFetchIsLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetchIsError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const fetchError: ACTION_ERROR_INTER = preFetchError as ACTION_ERROR_INTER;

    if (fetchError) {
      return fetchError.message;
    }

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  return (
    <Component
      onChangeSelect={onChangeSelect}
      onSubmit={onSubmit}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
    />
  );
};
