import React, { useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from './constant';
import { Component } from './component';
import { useMutation, useQueryClient } from 'react-query';
import { updateData } from './action';
import { EVENT_ADMIN_LIST_MODULE_NAME } from '../admin-event-list';
import { EVENT_ITEM_DATA_RAW_INTER } from '../event-list/constant';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { convertPreFetch } from './convert';

export const Container: React.FC<{
  data: EVENT_ITEM_DATA_RAW_INTER;
}> = ({ data }) => {
  const query = useQueryClient();
  const preFetch = convertPreFetch(data);

  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: preFetch.category,
    [FORM_VALUE_ENUM.DESCRIPTION]: preFetch.description,
    [FORM_VALUE_ENUM.IMG_URL]: preFetch.imgUrl,
    [FORM_VALUE_ENUM.LINK]: preFetch.link,
    [FORM_VALUE_ENUM.ORDER]: preFetch.order,
    [FORM_VALUE_ENUM.STATUS]: preFetch.status,
    [FORM_VALUE_ENUM.TAG]: preFetch.tag,
    [FORM_VALUE_ENUM.TITLE]: preFetch.title,
    [FORM_VALUE_ENUM.UTM]: preFetch.utmParam,
    [FORM_VALUE_ENUM.LANGUAGE_CODE]: preFetch.languageCode,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    onSubmit: () => {
      action.mutate();
    },
  });

  const onSuccess = () => {
    query.invalidateQueries(EVENT_ADMIN_LIST_MODULE_NAME);
  };

  const action = useMutation(
    () => updateData({ id: preFetch.id, payload: formik.values }),
    {
      onSuccess,
    },
  );

  const handleUpdate = () => {
    // action.mutate();
  };

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const isSuccess = () => {
    return action.isSuccess;
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, Array.isArray(e) ? e : [e]);
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      setValue={setValue}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      getFieldValue={getFieldValue}
      setFieldValue={setFieldValue}
      formik={formik}
      isLoading={isLoading()}
      handleUpdate={handleUpdate}
      isSubmitDisabled={isSubmitDisabled}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
