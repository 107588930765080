import { USER_EARNING_STATUS } from '../earning-admin-list/constant';

export const MODULE_NAME = 'USER_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const tabList = [
  { tid: 'EARNING.TAB.ALL', value: 'All' },
  { tid: 'EARNING.TAB.PENDING', value: USER_EARNING_STATUS.PENDING },
  { tid: 'EARNING.TAB.COMPLETE', value: USER_EARNING_STATUS.COMPLETE },
  { tid: 'EARNING.TAB.CANCEL', value: USER_EARNING_STATUS.CANCEL },
];

export enum FORM_VALUE_ENUM {
  USER = 'user',
  EVENT = 'event',
  DATE = 'date',
  STATUS = 'status',
}
