export enum LANGUAGE_TAG_ENUM {
  UK = 'uk', // Ukrainian
  RU = 'ru', // Russian
  PL = 'pl', // Polish
}

export type LANG_TYPE = `${LANGUAGE_TAG_ENUM}`;

export const LANGUAGE_VALUE = {
  [LANGUAGE_TAG_ENUM.UK]: 'UA',
  [LANGUAGE_TAG_ENUM.RU]: 'RU',
  [LANGUAGE_TAG_ENUM.PL]: 'PL',
};

export const LANGUAGE_EVENT_VALUE = {
  UA: 'uk',
  RU: 'ru',
  PL: 'pl',
};

export const LANGUAGE_LIST = [
  { label: 'Українська', value: LANGUAGE_TAG_ENUM.UK },
  { label: 'Русский', value: LANGUAGE_TAG_ENUM.RU },
  { label: 'Polski', value: LANGUAGE_TAG_ENUM.PL },
];

export const LANG_STATUS_VIEW = {
  [LANGUAGE_TAG_ENUM.UK]: 'Українська',
  [LANGUAGE_TAG_ENUM.RU]: 'Русский',
  [LANGUAGE_TAG_ENUM.PL]: 'Polski',
};
