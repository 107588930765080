import { LANGUAGE_TAG_ENUM } from '../../lib/lang/value';

export const MODULE_NAME = 'EVENT_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  EVENT: {
    TYPE: 'GET',
    URL: (params: string) => `/event/list/${params}`,
  },
  USER: {
    TYPE: 'GET',
    URL: `/user/telegram-id-data`,
  },
};

export const API_USER = {
  TYPE: 'GET',
  URL: (lang: string) => `/event/user/list?languageCode=${lang}`,
};

export enum EVENT_TAG {
  HOT = 1,
  NEW = 2,
}

export enum EVENT_CATEGORY {
  ONLINE = 1,
  OFFLINE = 2,
}

export enum EVENT_STATUS {
  ACTIVE = 1,
  DISABLED = 2,
}

export const EVENT_STATUS_VIEW = {
  [EVENT_STATUS.ACTIVE]: 'COMMON.STATUS.ACTIVE',
  [EVENT_STATUS.DISABLED]: 'COMMON.STATUS.DISABLED',
};

export const EVENT_TAG_VIEW = {
  [EVENT_TAG.HOT]: 'COMMON.TAG.HOT',
  [EVENT_TAG.NEW]: 'COMMON.TAG.NEW',
};

export const EVENT_CATEGORY_VIEW = {
  [EVENT_CATEGORY.ONLINE]: 'COMMON.CATEGORY.ONLINE',
  [EVENT_CATEGORY.OFFLINE]: 'COMMON.CATEGORY.OFFLINE',
};

export interface EVENT_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  title: string;
  description: string;
  link: string;
  category: EVENT_CATEGORY;
  tag: EVENT_TAG;
  imgUrl?: string;
  utmParam: string;
  status: EVENT_STATUS;
  order: number;
  languageCode: LANGUAGE_TAG_ENUM;
}

export interface EVENT_ITEM_LIST_DATA_RAW_INTER {
  list: EVENT_ITEM_DATA_RAW_INTER[];
}

export interface EVENT_ITEM_DATA_INTER
  extends Omit<
    EVENT_ITEM_DATA_RAW_INTER,
    'category' | 'status' | 'tag' | 'languageCode'
  > {
  category: {
    label: string;
    value: string;
  }[];
  status: {
    label: string;
    value: string;
  }[];
  tag: {
    label: string;
    value: string;
  }[];
  languageCode: {
    label: string;
    value: string;
  }[];
}

export interface EVENT_ITEM_LIST_DATA_INTER {
  list: EVENT_ITEM_DATA_RAW_INTER[]; // в event-list сделать конвертацию и сделать тут через DATA_ITER
  isEmpty: boolean;
}

export interface DATA_INTER extends EVENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER extends EVENT_ITEM_LIST_DATA_RAW_INTER {}
