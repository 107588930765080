import React, { useEffect, useRef, useState } from 'react';
import { Component } from './component';
import { getTelegramUserData } from '../../lib/util/telegramData';
import { useTranslation } from 'react-i18next';
import {
  LANG_TYPE,
  LANGUAGE_TAG_ENUM,
  LANGUAGE_VALUE,
} from '../../lib/lang/value';
import { DATA } from './constant';
import { useHistory, useLocation } from 'react-router';

export const Container: React.FC<{}> = ({}) => {
  const telegramUserData = getTelegramUserData();
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<
    | {
        lang: string;
        icon: string;
        value: LANGUAGE_TAG_ENUM;
      }
    | undefined
  >();

  useEffect(() => {
    const languageCode = telegramUserData?.user?.language_code;

    if (languageCode) {
      i18n.changeLanguage(LANGUAGE_VALUE[languageCode as LANG_TYPE]);
      const initLanguage = DATA.find((e) => e.lang === i18n.language);
      setCurrentLanguage(initLanguage);
    }
  }, [telegramUserData?.user?.language_code]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (language: {
    lang: string;
    icon: string;
    value: LANGUAGE_TAG_ENUM;
  }) => {
    setCurrentLanguage(language);
    history.push(location.pathname); // need for fix bug

    if (language) {
      i18n.changeLanguage(language.lang);
    }

    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Component
      currentLanguage={currentLanguage}
      toggleMenu={toggleMenu}
      handleClick={handleClick}
      containerRef={containerRef}
      isOpen={isOpen}
    />
  );
};
