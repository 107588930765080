import { USER_EARNING_STATUS } from '../earning-admin-list/constant';
import { DATA_INTER, RAW_INTER } from './constant';

export const convert = (raw: RAW_INTER): DATA_INTER => {
  const pendingBalance = raw?.earning
    ? raw.earning
        .filter((earning) => earning.status === USER_EARNING_STATUS.PENDING)
        .reduce((sum, earning) => sum + Number(earning.amount), 0)
    : 0;

  const totalBalance = raw?.earning
    ? raw.earning
        .filter((earning) => earning.status === USER_EARNING_STATUS.COMPLETE)
        .reduce((sum, earning) => sum + Number(earning.amount), 0)
    : 0;

  const referalUser = raw?.earning
    ? raw.earning.filter(
        (earning) =>
          earning.isPerson === true &&
          earning.status === USER_EARNING_STATUS.COMPLETE,
      ).length
    : 0;

  return {
    balance: raw.balance,
    pendingBalance,
    totalBalance,
    referalUser,
    level: raw.level,
  };
};
