import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';
import { DATA_INTER, EVENT_ITEM_DATA_RAW_INTER } from './constant';
import { SkeletonListElem } from '../../common/skeleton-list';
import { Card } from './frame/card';
import { EmptyListUserElem } from '../../common/empty-list-user-elem';
import { USER_ID_INTER } from '../../data/user/constant';
import { Spacing } from '../../theme';

export const Component: React.FC<{
  data?: DATA_INTER;
  userData?: USER_ID_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ data, isLoading, isSuccess, isError, errorMessage, userData }) => {
  return (
    <Container>
      <FlexContainer>
        {/* <AlertAccordionElem
          titleTid="EVENT.ALERT.TITLE"
          type="video"
          videoSrc="EVENT.ALERT.VIDEO"
          descriptionTid="EVENT.ALERT.DESCRIPTION"
        /> */}

        {isLoading && <SkeletonListElem />}
        {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
        {data?.isEmpty && !isLoading && <EmptyListUserElem />}
        {isSuccess &&
          data?.list.map((item: EVENT_ITEM_DATA_RAW_INTER) => (
            <React.Fragment key={item.id}>
              <Card
                telegramId={userData?.telegramId}
                invitedTelegramId={userData?.invitedTelegramId}
                {...item}
              />
            </React.Fragment>
          ))}
      </FlexContainer>
    </Container>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(6)};
`;

const Container = styled.div`
  width: 100%;
`;
