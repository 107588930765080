import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSubmit?: any;
  onChangeSelect: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  onSubmit,
  onChangeSelect,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="USER.UPDATE.SUCCESS" />
      )}
      <TitleContainer>
        <TextElemStyled
          tid="USER.UPDATE.TITLE"
          type="medium"
          color="default"
          size="input"
        />
        <TextElemStyled
          tid="USER.UPDATE.DESCRIPTION"
          color="white"
          size="alert"
        />
      </TitleContainer>

      <Container>
        <FieldTextElem
          isRequired
          title="USER.UPDATE.EMAIL"
          name={FORM_VALUE_ENUM.EMAIL}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
          placeholder="USER.UPDATE.EMAIL"
          errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
          error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
        />
        <FieldTextElem
          isRequired
          title="USER.UPDATE.FULL_NAME"
          name={FORM_VALUE_ENUM.FULL_NAME}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.FULL_NAME)}
          placeholder="USER.UPDATE.FULL_NAME"
          errorMessage={getFieldError(FORM_VALUE_ENUM.FULL_NAME)}
          error={isFieldError(FORM_VALUE_ENUM.FULL_NAME)}
        />

        <FieldTextElem
          isRequired
          title="USER.UPDATE.PHONE"
          name={FORM_VALUE_ENUM.PHONE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
          placeholder="USER.UPDATE.PHONE"
          errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
          error={isFieldError(FORM_VALUE_ENUM.PHONE)}
          type="phone"
        />

        <ButtonElem
          disabled={isSubmitDisabled()}
          type="primary"
          tid="USER.UPDATE.BUTTON"
          onClick={onSubmit}
        />
      </Container>
    </>
  );
};

const TextElemStyled = styled(TextElem)`
  line-height: 1.6em;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: ${Spacing(5)} ${Spacing(4)};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
  padding-bottom: ${Spacing(50)};
`;
