import { API } from './constant';
import { HttpRequest } from '../../lib/http';
import { convert } from './convert';

export const updateData = ({ id, payload }: any) => {
  return HttpRequest({
    method: API.UPDATE.TYPE,
    url: API.UPDATE.URL(id),
    data: convert(payload),
  });
};
