import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { DATA } from './constant';
import arrowSvg from '../../asset/svg/lang/arrow.svg';
import acceptSvg from '../../asset/svg/lang/accept.svg';
import { LANGUAGE_TAG_ENUM } from '../../lib/lang/value';

export const Component: React.FC<{
  currentLanguage?: {
    lang: string;
    icon: string;
    value: LANGUAGE_TAG_ENUM;
  };
  toggleMenu: () => void;
  handleClick: Function;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
}> = ({ currentLanguage, toggleMenu, handleClick, containerRef, isOpen }) => {
  return (
    <Container ref={containerRef}>
      <MenuButton isOpen={isOpen} onClick={toggleMenu}>
        <img
          src={currentLanguage?.icon}
          alt={currentLanguage?.lang}
          width="20"
          height="20"
        />
        <LangText
          tid={currentLanguage?.lang}
          type="semi-bold"
          size="small"
          color="textPrimary"
        />
        {!isOpen && (
          <SvgImg src={arrowSvg} alt="arrow" width="20" height="20" />
        )}
      </MenuButton>
      <MenuContent isOpen={isOpen}>
        {DATA.map((item) => {
          const isActive = currentLanguage?.lang === item.lang;
          return (
            <MenuItem
              isActive={isActive}
              key={item.lang}
              onClick={() => handleClick(item)}
            >
              <img src={item.icon} alt={item.lang} width="20" height="20" />
              <TextElem
                tid={item.lang}
                type="semi-bold"
                size="small"
                color="textPrimary"
              />{' '}
              {isActive && <SvgImg alt="accepted" src={acceptSvg} />}
            </MenuItem>
          );
        })}
      </MenuContent>
    </Container>
  );
};

const LangText = styled(TextElem)``;

const SvgImg = styled.img`
  height: 16px;
  width: 16px;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: max-content;
  z-index: 1000;
`;

const MenuButton = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding: ${Spacing(3)};
  width: 93px;
  gap: ${Spacing(2)};
  border-top-left-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.BLOCK
  ]}px;
  border-top-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.BLOCK
  ]}px;

  border: 1px solid transparent;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DISPLAY]};

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${({ isOpen }) =>
      !isOpen && VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  ${({ isOpen }) => {
    if (isOpen) {
      return css`
        border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

        background-color: ${({ theme }) =>
          theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      `;
    }
  }}
`;

const MenuContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 45px;
  animation: ${(props) => (props.isOpen ? openMenu : closeMenu)} 0.3s ease-out;

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  border-bottom-left-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.BLOCK
  ]}px;
  border-bottom-right-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.BLOCK
  ]}px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const openMenu = keyframes`
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const closeMenu = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(0px);
  }
`;

const MenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: ${Spacing(2)};
  padding: ${Spacing(3)};
  width: 91px;
  cursor: pointer;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${({ isActive }) =>
      !isActive && VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
  }
`;
