import { USER_GIFT } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'WITHDRAW_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  STATUS = 'status',
  COMMENT = 'comment',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.STATUS]: string;
  [FORM_VALUE_ENUM.COMMENT]: string;
}

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `withdraw/admin-withdraw-list/${params}`,
};

// export interface ACTION_RESPONSE_INTER
//   extends WITHDRAW_ITEM_LIST_DATA_RAW_INTER {}

// export interface DATA_INTER extends EARNING_ITEM_LIST_DATA_INTER {}

export enum USER_WITHDRAW_STATUS {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  CANCEL = 'Cancel',
  FOR_PAYMENT = 'For_payment',
}

export enum USER_WITHDRAW_TYPE {
  MONEY = 'MONEY',
  GIFT = 'GIFT',
}

export interface WITHDRAW_ITEM_DATA_RAW_INTER {
  id: string;
  amount: string;
  status: USER_WITHDRAW_STATUS;
  comment: string;
  address: string;
  wallet: string;
  type: USER_WITHDRAW_TYPE;
  createDate: string;
  gift: USER_GIFT;
  user: {
    id: string;
    telegramId: string;
    userName: string;
    firstName: string;
    lastName: string;
    balance: string;
  };
}

export interface WITHDRAW_ITEM_LIST_DATA_RAW_INTER {
  list: WITHDRAW_ITEM_DATA_RAW_INTER[];
}

export interface WITHDRAW_ITEM_DATA_INTER
  extends Omit<WITHDRAW_ITEM_DATA_RAW_INTER, 'status'> {
  status: {
    label: string;
    value: string;
  };
}

export interface WITHDRAW_ITEM_LIST_DATA_INTER {
  list?: WITHDRAW_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export interface DATA_INTER extends WITHDRAW_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER
  extends WITHDRAW_ITEM_LIST_DATA_RAW_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const STATUS_OPTION_LIST = [
  {
    value: USER_WITHDRAW_STATUS.COMPLETE,
    label: i18n.t('WITHDRAW.STATUS.COMPLETE'),
  },
  {
    value: USER_WITHDRAW_STATUS.CANCEL,
    label: i18n.t('WITHDRAW.STATUS.CANCEL'),
  },
  {
    value: USER_WITHDRAW_STATUS.PENDING,
    label: i18n.t('WITHDRAW.STATUS.PENDING'),
  },
  {
    value: USER_WITHDRAW_STATUS.FOR_PAYMENT,
    label: i18n.t('WITHDRAW.STATUS.FOR_PAYMENT'),
  },
];

export const WITHDRAW_STATUS_VIEW = {
  [USER_WITHDRAW_STATUS.PENDING]: i18n.t('EARNING.STATUS.PENDING'),
  [USER_WITHDRAW_STATUS.COMPLETE]: i18n.t('EARNING.STATUS.COMPLETE'),
  [USER_WITHDRAW_STATUS.CANCEL]: i18n.t('EARNING.STATUS.CANCEL'),
  [USER_WITHDRAW_STATUS.FOR_PAYMENT]: i18n.t('EARNING.STATUS.FOR_PAYMENT'),
};
