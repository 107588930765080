import React from 'react';
import { useQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  ACTION_RESPONSE_INTER,
  DATA_INTER,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { actionList, actionUserId } from './action';
import { USER_ID_INTER } from '../../data/user/constant';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_EVENT_VALUE } from '../../lib/lang/value';

export const Container: React.FC = () => {
  const { i18n } = useTranslation();
  const langKey = i18n.language as keyof typeof LANGUAGE_EVENT_VALUE;

  const lang = LANGUAGE_EVENT_VALUE[langKey];

  const preFetch = useQuery([MODULE_NAME, lang], () => actionList(lang));
  const prefetchUser = useQuery('userIdData', actionUserId);

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const isSuccess = () => {
    if (
      preFetch.isSuccess &&
      !preFetch.isFetching &&
      prefetchUser.isSuccess &&
      !prefetchUser.isFetching
    ) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      return { ...data, isEmpty: data?.list?.length < 1 };
    }
  };

  const getUserData = (): USER_ID_INTER | undefined => {
    const data = prefetchUser.data as unknown as USER_ID_INTER;

    if (data) {
      return data;
    }
  };

  return (
    <Component
      userData={getUserData()}
      data={getData()}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
    />
  );
};
