import { USER_EARNING_STATUS } from '../earning-admin-list/constant';
import { USER_LVL } from '../user-list/constant';

export const MODULE_NAME = 'USER_BALANCE_ANALYTIC_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: '/user/balance',
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface RAW_INTER {
  balance: number;
  level: USER_LVL;
  earning: {
    amount: number;
    isPerson: boolean;
    status: USER_EARNING_STATUS;
  }[];
}

export interface DATA_INTER {
  balance: number;
  pendingBalance: number;
  referalUser: number;
  totalBalance: number;
  level: number;
}
