export const MODULE_NAME = 'WITHDRAW_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const API = {
  TYPE: 'POST',
  URL: 'withdraw/create-withdraw',
};

export enum FORM_VALUE_ENUM {
  WALLET = 'wallet',
  AMOUNT = 'amount',
  CARD = 'card',
  FULL_NAME = 'fullName',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.WALLET]: string;
  [FORM_VALUE_ENUM.CARD]: string;
  [FORM_VALUE_ENUM.FULL_NAME]: string;
  [FORM_VALUE_ENUM.AMOUNT]: number;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export enum WITHDRAW_MONEY_ENUM {
  BANK = 'Банковская-карта',
  WALLET = 'USDT-TRC20',
}

export enum WITHDRAW_CREATE_TAB {
  WALLET = 1,
  CARD = 2,
}

export const tabList = [
  { tid: 'WITHDRAW.CREATE.TAB.WALLET', value: WITHDRAW_CREATE_TAB.WALLET },
  { tid: 'WITHDRAW.CREATE.TAB.CARD', value: WITHDRAW_CREATE_TAB.CARD },
];
