import React from 'react';
import { RouteComponentProps } from 'react-router';

import { AccountProfileContainer } from '../../epic/account-profile';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { UserEarningListContainer } from '../../epic/user-earning-list';
import { UserBalanceAnalyticContainer } from '../../epic/user-balance-analytic';
// import { UserGiftAnalyticContainer } from '../../epic/user-gift-analytic';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <UserBalanceAnalyticContainer />
      <ProfileContainer>
        <AccountProfileContainer />
        {/* <UserGiftAnalyticContainer /> */}
      </ProfileContainer>
      <UserEarningListContainer />
    </Layout>
  );
};

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Layout = styled.div`
  display: flex;
  gap: ${Spacing(7)};
  flex-direction: column;
  width: 100%;
`;
