import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FormikValues } from 'formik';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { FORM_VALUE_ENUM, tabList, WITHDRAW_CREATE_TAB } from './constant';
import { TextElem } from '../../common/text';
import { TabElem } from '../../common/tab';

export const Component: React.FC<{
  formik: FormikValues;
  getFieldError: Function;
  isSubmitDisabled: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onSubmit?: any;
  getFieldValue: Function;
  isFieldError: Function;
  setFieldValue: Function;
  activeTab: number;
  setActiveTab: Function;
}> = ({
  isError,
  errorMessage,
  isSuccess,
  isLoading,
  isSubmitDisabled,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  activeTab,
  setActiveTab,
}) => {
  return (
    <>
      <Container>
        <TextElem
          size="alert"
          color="textPrimary"
          tid="WITHDRAW.CREATE.DESCRIPTION"
        />
        <TabElem
          active={activeTab}
          setActive={(e: number) => {
            formik.resetForm();
            setActiveTab(e);
          }}
          tabList={tabList}
          justifyContent="flex-start"
        />

        <FiledContainer>
          {activeTab === WITHDRAW_CREATE_TAB.WALLET && (
            <>
              <FieldTextElem
                title="WITHDRAW.CREATE.AMOUNT"
                placeholder="WITHDRAW.CREATE.AMOUNT_PLACEHOLDER_WALLET"
                name={FORM_VALUE_ENUM.AMOUNT}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
                error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
                type="number"
              />
              <FieldTextElem
                title="WITHDRAW.CREATE.WALLET_TITLE"
                placeholder="WITHDRAW.CREATE.WALLET"
                name={FORM_VALUE_ENUM.WALLET}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.WALLET)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.WALLET)}
                error={isFieldError(FORM_VALUE_ENUM.WALLET)}
                type="text"
              />
            </>
          )}

          {activeTab === WITHDRAW_CREATE_TAB.CARD && (
            <>
              <FieldContainer>
                <FieldTextElem
                  title="WITHDRAW.CREATE.AMOUNT_PLACEHOLDER_CARD"
                  placeholder="WITHDRAW.CREATE.AMOUNT"
                  name={FORM_VALUE_ENUM.AMOUNT}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
                  error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
                  type="number"
                  min={0}
                />
                <TextElem
                  size="small"
                  type="medium"
                  color="textSecondary"
                  tid="WITHDRAW.CREATE.AMOUNT_HELP"
                />
              </FieldContainer>
              <FieldContainer>
                <FieldTextElem
                  title="WITHDRAW.CREATE.CARD_TITLE"
                  placeholder="WITHDRAW.CREATE.CARD_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.CARD}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getFieldValue(FORM_VALUE_ENUM.CARD)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.CARD)}
                  error={isFieldError(FORM_VALUE_ENUM.CARD)}
                  type="text"
                />
                <TextElem
                  size="small"
                  type="medium"
                  color="textSecondary"
                  tid="WITHDRAW.CREATE.CARD_HELP"
                />
              </FieldContainer>
              <FieldTextElem
                title="WITHDRAW.CREATE.FULL_NAME_TITLE"
                placeholder="WITHDRAW.CREATE.FULL_NAME_PLACEHOLDER"
                name={FORM_VALUE_ENUM.FULL_NAME}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.FULL_NAME)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.FULL_NAME)}
                error={isFieldError(FORM_VALUE_ENUM.FULL_NAME)}
                type="text"
              />
            </>
          )}
        </FiledContainer>
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="primary"
          tid="WITHDRAW.CREATE.BUTTON"
          onClick={formik.handleSubmit}
        />
      </Container>
      {isError && <AlertActionElem tid={`ERROR.${errorMessage}`} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  align-items: start;
  text-align: start;
`;

const FiledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-gap: ${Spacing(7)};
  text-align: center;
`;
