import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => ({
  ...data,
  email: data[FORM_VALUE_ENUM.EMAIL]
    ? data[FORM_VALUE_ENUM.EMAIL].toLowerCase().trim()
    : '',
});

export const convertArray = (
  array: string[] | null | any,
  viewObj: Record<string, string>,
) =>
  array
    ? array.map((e: number) => ({
        label: viewObj[Number(e)],
        value: Number(e),
      }))
    : null;
